import {capitalize, initials, substringIf, translate, money, escapeHTML, renderMarkdown} from "src/filters/Common";
import {formatDate, formatDateRelative, formatDateTime, formatDateTimeWithoutTimezone, formatTime} from "src/filters/Date";
import {convertBytes} from "src/lib/helpers/Common";
import {dictionary} from "src/filters/AppSpecific";

export default ({Vue}) => {
  Vue.filter('initials', (value) => {
    return initials(value);
  });

  Vue.filter('capitalize', (value) => {
    return capitalize(value);
  });

  Vue.filter('dictionary', (value, namespace) => {
    return dictionary(value, namespace);
  });

  Vue.filter('money', function (value) {
    return money(value);
  });


  Vue.filter('convertBytes', (value) => {
    return convertBytes(value);
  })

  Vue.filter('ceil', (value) => {
    return Math.ceil(value);
  });

  Vue.filter('translate', (value) => {
    return translate(value);
  });

  Vue.filter('formatDate', (value) => {

    return formatDate(value);
  });

  Vue.filter('formatTime', (value) => {
    return formatTime(value);
  });

  Vue.filter('yesNo', (value) => {
    if (value) {
      return translate('yes');
    } else {
      return translate('no');
    }
  });

  Vue.filter('formatDateTime', (value) => {
    return formatDateTime(value);
  });

  Vue.filter('escapeHtml', (value) => {
    return escapeHTML(value);
  });

  Vue.filter('formatDateRelative', (value) => {
    return formatDateRelative(value);
  });

  Vue.filter('formatDateTimeWithoutTimezone', (value) => {
    return formatDateTimeWithoutTimezone(value);
  });

  Vue.filter('substringIf', (value, start, end, add) => {
    return substringIf(value, start, end, add);
  });

  Vue.filter('renderMarkdown',(text) => {
    return renderMarkdown(text);
  });
}
