import moment from "moment-timezone";
import store from '../store/index';

/**
 * Format Date base on user settings
 *
 * @param date
 * @returns {*}
 */
export const formatDate = (date) => {

  let user = store.getters['auth/user'];

  return (date) ? moment(date).format(user.date_format) : '';

}

/**
 * Helper function to format date time
 * @param date
 * @param applyTimezone
 * @returns {string|*}
 */
export const formatDateTimeHelper = (date, applyTimezone) => {
  if (!date) {
    return '';
  }
  let user = store.getters['auth/user'];

  let timeZone = user.time_zone;
  let dateFormat = user.date_format;
  let timeFormat = user.time_format;

  let momentDate = moment.utc(date);
  if (applyTimezone) {
    momentDate = momentDate.tz(timeZone);
  }

  return momentDate.format(dateFormat + ' ' + timeFormat);
}

/**
 * Format Date Time base on user settings without timezone
 * @param date
 * @returns {string|*}
 */
export const formatDateTimeWithoutTimezone = (date) => {
  return formatDateTimeHelper(date, false);
}

/**
 * Format Date Time base on user settings
 * @param date
 * @returns {string}
 */
export const formatDateTime = (date) => {
  return formatDateTimeHelper(date, true);
}


/**
 * Parse date using user time zone. Returns formatted UTC Date + Time
 * @param date
 * @param df
 * @returns {string}
 */
export const parseDateTime = (initDate, df = '', disableTimezone = false) => {

  if(!initDate){
    return null;
  }
  let user = store.getters['auth/user'];

  let timeZone = user.time_zone;
  let dateFormat = user.date_format;
  let timeFormat = user.time_format;

  let date;

  if(dateFormat === 'MM/DD/YYYY'){
    date = moment.tz(initDate, "MM/DD/YYYY hh:mm A", timeZone);
  }
  else if(dateFormat === 'MM/DD/YY'){
    date = moment.tz(initDate, "MM/DD/YY hh:mm A", timeZone);
  }
  else if(dateFormat === 'YYYY/MM/DD'){
    date = moment.tz(initDate, "YYYY/MM/DD hh:mm A", timeZone);
  } else{
    date = moment.tz(initDate, "YYYY-MM-DD hh:mm A", timeZone);
  }
  if (df === '') {
    df = dateFormat + ' ' + timeFormat;
  }

  if (disableTimezone) {
    return moment(date).format(df);
  }

  return moment(date).utc().format(df);
}


export const parseDateTimeToUTC = (date, df = '') => {
  let user = store.getters['auth/user'];

  let timeZone = user.time_zone;

  return moment(date).tz(timeZone).utc().format('YYYY-MM-DD HH:mm:ss');

}

/**
 * Parse date using user time zone. Returns formatted UTC Date
 * @param date
 * @param df
 * @returns {string}
 */
export const parseDate = (date, df = '') => {

  let user = store.getters['auth/user'];

  let timeZone = user.time_zone;
  let dateFormat = user.date_format;
  let timeFormat = user.time_format;

  if (df === '') {
    df = dateFormat + ' ' + timeFormat;
  }

  return moment(date).format(df);
}

/**
 * Format Time base on user settings
 * @param date
 * @returns {string}
 */
export const formatTime = (date) => {

  if (!date) {
    return '';
  }
  let user = store.getters['auth/user'];

  let timeZone = user.time_zone;

  let timeFormat = user.time_format;

  return moment.utc(date).tz(timeZone).format(timeFormat);
}

/**
 * Format relative time base on user settings
 * @param date
 * @returns {string}
 */
export const formatDateRelative = (date) => {

  if (!date) {
    return '';
  }
  let user = store.getters['auth/user'];

  let timeZone = user.time_zone;
  let dateFormat = user.date_format;
  let timeFormat = user.time_format;

  return moment.utc(date).tz(timeZone).from(moment().tz(timeZone));
}
export default {
  formatDate,
  formatDateTime,
  formatDateRelative,
  formatTime,
  parseDateTime,
  parseDate,
  parseDateTimeToUTC,
  formatDateTimeWithoutTimezone

}
